<template>
    <div>
        <!-- Header -->
        <section class="header">
            <NotificationBell v-show="false" @get-point="getPointHandler" />

            <div class="icon"></div>
            <div class="flexH">
                <div class="img"><img src="@/assets/img/favicon-b.svg" /></div>
                <div class="img"><img src="@/assets/img/title.svg" /></div>
            </div>
            <a
                class="icon iconButton"
                @click.prevent="$router.push('/notification')"
            >
                <img src="@/assets/icon/bell.svg" />
                <div class="new">
                    <span class="fXS white">{{ countUnread }}</span>
                </div>
            </a>
        </section>
        <NotificationBar
            :open="isNotificationBar"
            :speed="2.3"
            :content="notificationBarContent"
            @close="closeNotificationBar()"
        />
    </div>
</template>
<script>
import { mapState } from "vuex";
import NotificationBell from "@/components/NotificationBell.vue";
import NotificationBar from "@/components/NotificationBar";
// import { db } from "@/db.js";

export default {
    name: "Header",
    components: {
        NotificationBell,
        NotificationBar
    },
    props: {
        countUnread: Number
    },
    data: function () {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            isNotificationBar: false,
            notificationBarContent: {
                title: "",
                message: "",
                time: "",
                points: "",
                reset: function () {
                    this.title = "";
                    this.message = "";
                    this.time = "";
                    this.points = "";
                }
            },
            firstNotification: {}
        };
    },
    computed: {
        ...mapState(["user"])
    },
    mounted() {},
    methods: {
        closeNotificationBar() {
            this.isNotificationBar = false;
            this.notificationBarContent.reset();
        },
        showNotificationBar(title, message) {
            this.readPopupMsg();
            this.isNotificationBar = true;
            this.notificationBarContent.reset();
            this.notificationBarContent.title = title;
            this.notificationBarContent.message = message;
        },
        readPopupMsg() {
            // let collection = db.collection(
            //     `notifications/${this.merchantId}/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // if (this.firstNotification.docId) {
            //     let docId = this.firstNotification.docId;
            //     let docRef = collection.doc(docId);
            //     let batch = db.batch();
            //     batch.update(docRef, { getNotify: true });
            //     // batch.update(docRef, {unread: false});
            //     batch.commit().then(() => {
            //         console.log("update unread!");
            //     });
            //     this.firstNotification.docId = null;
            // }
            //NOTE: Howard's fix: use API to mark read instead of writing Firestore directly.
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/air3/v3/merchants/tycard2022/firestore-mark-read-legacy`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    messageId: this.firstNotification.docId
                }
            }
            return this.$http(config);
        },
        getPointHandler(event) {
            console.log("get points: ", event);
            this.closeNotificationBar();
            this.firstNotification = event;
            let title = this.firstNotification.title;
            let message = this.firstNotification.text;
            this.showNotificationBar(title, message);
        }
    }
};
</script>
